import { env } from './EnvironmentConfig';

export const APP_NAME = 'App Name';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_REFETCH_TIME = env.REFETCH_TIME;
export const API_AUCTION_REFETCH_TIME = env.AUCTION_REFETCH_TIME;
export const APP_PREFIX_PATH = '';
export const APP_IN_PRODUCTION = process.env.NODE_ENV === 'production';
export const APP_IMAGE_BASE_URL = "https://vivifiapitest.riolabz.com/";
// export const APP_IMAGE_BASE_URL = env.API_MODE === "Live" ? "https://www.vivifipharma.com/" : "https://vivifiapitest.riolabz.com/";
export const APP_EMPLOYEE_MAIL = "https://mail.vivifipharma.com/webmail";
export const APP_EMPLOYEE_LOGIN = "https://www.vivifipharma.com/portal/index.php";