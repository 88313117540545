import { useDispatch, useSelector } from "react-redux";

import { modalActions } from "store-redux/modal-slice";
import {
  NavbarLinks,
  facebookLink,
  googlePlusLink,
  linkedInLink,
  twitterLink,
} from "helpers/constants";

import InputForm from "components/forms/input/InputForm";
import HumburgerButton from "components/UI/button/HumburgerButton";
import Signup from "components/modal/sign-up/Signup";
import SignupDetails from "components/modal/sign-up/SignupDetails";
import SignIn from "components/modal/sign-in/SignIn";
import Button from "components/UI/button/Button";

import NavbarList from "./navbar-list/NavbarList";
import NavbarLogo from "./navbar-logo/NavbarLogo";
import SideNavbar from "./side-navbar/SideNavbar";
import NavbarListIcon from "./navbar-list-icon/NavbarListIcon";

import classes from "./Navbar.module.css";
import ResetPassword from "components/modal/reset-password/ResetPassword";
import { Link } from "react-router-dom";
import {
  API_BASE_URL,
  APP_EMPLOYEE_LOGIN,
  APP_EMPLOYEE_MAIL,
} from "configs/AppConfig";
import useWindowWidth from "helpers/useWindowWidth";

// Navbar component => the Parent Component for the Navbar elements
// all the navbar related components are child for this component
let emailValue = "";
const Navbar = () => {
  const dispatch = useDispatch();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);

  //----------Login modals and buttons in navbar --------
  // const setSigninModalHandler = () => {
  //   dispatch(
  //     modalActions.changeModalComponent({
  //       modalType: 'signin'
  //     })
  //   );
  // };

  // const setSignupDetailsModalHandler = (value) => {
  //   emailValue = value;
  //   dispatch(
  //     modalActions.changeModalComponent({
  //       modalType: 'signupDetails'
  //     })
  //   );
  // };

  // const renderBrowserNavButtons = () => {
  //   if (!isLoggedIn) {
  //     return (
  //       <Button type="button" option="colored" onClick={setSigninModalHandler}>
  //         Sign in
  //       </Button>
  //     );
  //   }

  //   return <NavbarListIcon setSigninModalHandler={setSigninModalHandler} />;
  // };

  const setSideNavbarIsOpenHandler = () => {
    if (modalType === "sidenav") {
      dispatch(modalActions.toggleModal());
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "sidenav",
        })
      );
    }
  };

  const closeModalHandler = () => {
    if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  };

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  //----------Modal components corresponding to required functionality------
  const renderModal = () => {
    if (modalIsVisible) {
      // if (modalType === 'signup') {
      //   return (
      //     <Signup
      //       showSignupDetailsModal={setSignupDetailsModalHandler}
      //       onClose={toggleModalHandler}
      //     />
      //   );
      // }

      if (modalType === "signupDetails") {
        return (
          <SignupDetails emailValue={emailValue} onClose={toggleModalHandler} />
        );
      }

      if (modalType === "signin") {
        return <SignIn onClose={toggleModalHandler} />;
      }

      if (modalType === "resetpassword") {
        return <ResetPassword onClose={toggleModalHandler} />;
      }

      if (modalType === "sidenav") {
        return <SideNavbar onClose={toggleModalHandler} />;
      }
    }

    return;
  };

  const width = useWindowWidth();

  return (
    <div className={`${classes.mainSection}`}>
      {width > 1020 && (
        <div className={`${classes.topSection}`}>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <img src="/img/icons/facebook.svg" alt="fb" />
          </a>
          <a href={linkedInLink} target="_blank" rel="noopener noreferrer">
            <img src="/img/icons/linkedin.svg" alt="ln" />
          </a>
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <img src="/img/icons/twitter.svg" alt="x" />
          </a>
          <a href={googlePlusLink} target="_blank" rel="noopener noreferrer">
            <img src="/img/icons/google-plus.svg" alt="g" />
          </a>
        </div>
      )}
      <div className={`${classes.mainNavbar}`}>
        <NavbarLogo onClick={closeModalHandler} />
        <div className={classes.navbarList}>
          <NavbarList navList={NavbarLinks} />
          {/* <InputForm
          name="search cars"
          placeholder="Search for cars (ex.  BMW, Audi)"
          onSubmit={() => {}}
        /> */}
          {/* {renderBrowserNavButtons()} */}
          <div className={classes.buttons}>
            <Button
              option="uncolored"
              onClick={() => window.open(APP_EMPLOYEE_MAIL, "_blank")}
            >
              Employee Mail
            </Button>
            <Button
              option="colored"
              onClick={() => window.open(APP_EMPLOYEE_LOGIN, "_blank")}
            >
              Employee Login
            </Button>
          </div>
        </div>
        <HumburgerButton
          className={classes.humburgerButton}
          onClick={setSideNavbarIsOpenHandler}
          open={modalIsVisible && modalType === "sidenav"}
        />
        {renderModal()}
      </div>
    </div>
  );
};

export default Navbar;
